[data-file-upload-field] {
  .progress {
    margin-bottom: 20px;
  }

  [data-file-list] {
    padding: 0;

    li {
      list-style: none;
    }
  }

  [data-drop-files] {
    border: 3px dashed rgba(0, 0, 0, 0.4);
    padding: 50px;
    text-align: center;
    font-size: 20px;
    color: rgba(0, 0, 0, 0.4);
    background-color: white;

    &.hover {
      border: 3px dashed rgba(0, 0, 0, 0.5);
      color: rgba(0, 0, 0, 0.5);
    }

    i {
      font-size: 50px;
    }
  }

  [data-upload-file-list] {
    padding: 0;
    margin-top: 20px;
    margin-bottom: 30px;

    li {
      list-style: none;
    }
  }
}

[data-images] {
  [data-file-list] {
    display: flex;
    display: -webkit-flex;
    flex-wrap: wrap;
    li {
      width: 20%;
      min-width: 223px;
      border: 1px solid rgba(0, 0, 0, 0.1);
      padding: 20px;
      overflow: scroll;
    }
    img {
      max-width: 100%;
    }
  }
}

.image-thumbnail-container {
  position: relative;
  background-color: white;
}

.image-thumbnail-canvas {
  width: 223px;
  height: 223px;
  max-height: 100%;
  max-width: 100%;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.delete-file-link,
.delete-image-link {
  color: #dc3545;
  &:hover,
  &:focus,
  &:active {
    color: #dc3545;
  }
}

.image-thumbnail-actions {
  position: absolute;
  top: 8px;
  right: 5px;
  background-color: white;
  padding-bottom: 5px;

  > a {
    margin-left: 5px;
    padding: 5px;
  }
}
