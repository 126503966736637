@import "_variables.scss";

.content {
  //min-height: calc(100vh - 60px);
}

body, dialog {
  background-color: $firmItSemiLightBlue;
  font-family: $fontFamily;
  font-size: 14px;
  color: $colorDarkBlue;
}

a,
a:hover {
  text-decoration: none;
}

.btn {
  font-size: 0.9em;
}

.external::after {
  display: none;
  content: "\f35d";
  font-family: "Font Awesome 5 Solid";
}

.form-error-icon {
  margin-right: 5px;
}

.locale-switcher-content {
  display: flex;
  max-width: 0;
  overflow: hidden;
  align-items: center;
  transition: max-width 0.3s ease-in-out;

  .locale-switcher-item {
    text-transform: uppercase;
    color: $firmItGray;
    padding: 10px 8px;

    &.current {
      color: $colorDarkBlue;
    }

    &:hover {
      color: $colorBlue;
    }
  }

  .locale-switcher-item:first-child {
    margin-left: 8px;
  }
}

#locale-switcher-input:checked + .locale-switcher-content {
  max-width: calc(var(--locale-count) * (2ch + 16px) + 8px);
}

.btn-locale-switcher {
  color: $colorDarkBlue;
  background-color: white;
  border: 1.5px solid $colorDarkBlue;
  margin-bottom: 5px;
  margin-right: 5px;
  padding: 0!important;

  &:hover:not(.disabled) {
    background-color: white;
    color: $colorDarkBlue;
    border: 1.5px solid $colorDarkBlue;
  }
}
