.progress-vertical {
  .progress-item {
    display: flex;

    &.inactive {
      opacity: 0.2;
    }

    .progress-item-bullet {
      padding: 0 30px 0;
      position: relative;

      .progress-icon {
        width: 40px;
        height: 40px;
        border-radius: 5px;
        background: #173c5d;
        color: white;
        display: flex;
        align-items: center;
        justify-content: center;

        i,
        svg {
          font-size: 1.25rem;
        }

        .green-tick-box {
          position: absolute;
          top: 30px;
          left: 60px;
          width: 22px;
          height: 22px;
          background: green;
          display: inline-block;
          font-style: normal;
          font-variant: normal;
          text-rendering: auto;
          -webkit-font-smoothing: antialiased;
          font-weight: 900;
          border-radius: 5px;
          padding-left: 5px;
          i,
          svg {
            font-size: 0.75rem;
          }
        }
      }

      .progress-icon-green {
        background: green;
      }

      .progress-bar-vertical {
        width: 6px;
        background: #173c5d;
        position: absolute;
        top: 40px;
        bottom: 0;
        left: 0;
        right: 0;
        margin: 0 auto;
      }
    }

    .progress-item-content {
      padding-top: 5px;
      padding-bottom: 30px;

      h4 {
        font-size: 1rem;
      }
    }
    &:last-child {
      .progress-bar-vertical {
        display: none;
      }
    }
  }
}
