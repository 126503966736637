<template>
  <div class="upper">
    <div>
      <p>{{ process.name }}</p>
      <h5>
        {{ translate('process', `company_process.type.${process.type}`) }}
      </h5>
    </div>
    <div class="flex flex-row items-center">
      <span class="me-4">
        {{
          translate('messages', 'successful_esig', {
            '%currentSignatures%': currentSignatures.toString(),
            '%totalSignatures%': totalSignatures.toString(),
          })
        }}</span
      >
      <a class="card-layout" :href="`/process/${process.id}`">
        <i class="far fa-eye"></i>
      </a>
    </div>
  </div>
  <div class="flex justify-center">
    <h5
      class="btn btn-primary btn-small position-absolute collapsible collapsed"
      data-bs-toggle="collapse"
      :data-bs-target="`#process-${process.id}`"
      aria-expanded="false"
      :aria-controls="`#process-${process.id}`"
      type="button"
      style="margin-top: -0.6rem; padding: 0.1rem 0.3rem"
    >
      <i class="far fa-chevron-down"></i>
    </h5>
  </div>
  <div :id="`process-${process.id}`" class="collapse">
    <div class="lower">
      <div
        v-for="(doc, index) of process.eSigDocs"
        :key="doc"
        class="flex flex-row justify-between"
      >
        <div :class="index === process.eSigDocs.length - 1 ? '' : 'mb-3'">
          <h6 class="mb-1">
            {{ translate('document', `type.${doc.documentType}`) }}
          </h6>
          <span v-if="doc.initialisationDate">{{
            formatDateTime(doc.initialisationDate, true)
          }}</span>
        </div>

        <h5 v-if="doc.signingDeclined" class="text-danger">
          {{ translate('process', 'e_signature_signing_phase.signing_declined') }}
        </h5>
        <p v-else class="mb-0">
          {{
            translate('process', 'e_signature_signing_phase.documents_text', {
              '%numberOfPeople%': doc.totalSignedSigners,
              '%totalNumberOfPeople%': doc.totalSigners,
            })
          }}
        </p>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { defineOptions } from 'vue';
import { formatDateTime, translate } from '@/js/frontend/filters';
import { ESigProcess } from '@/interfaces/eSigProcess';

defineOptions({
  name: 'EsignCard',
});

interface Props {
  process: ESigProcess;
}

const props = defineProps<Props>();

const totalSignatures = computed(() =>
  props.process.eSigDocs.reduce((acc, doc) => acc + doc.totalSigners, 0)
);
const currentSignatures = computed(() =>
  props.process.eSigDocs.reduce((acc, doc) => acc + doc.totalSignedSigners, 0)
);
</script>

<style scoped>
.btn:focus,
.btn:active:focus,
.btn:active:focus {
  outline: none;
  box-shadow: none;
}
</style>
