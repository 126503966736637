
.payment-table {
  border-collapse: collapse;
  tr {
    height: 3rem;

    td {
      vertical-align: middle;
      padding: 0.25rem 0.25rem;

      &:first-child {
        padding-left: 1.75rem;
      }

      &:last-child {
        padding-right: 1.75rem;
        text-align: end;
      }
    }
  }
}

.payment-option {
  @apply relative px-8 py-8 text-center rounded-2xl border-2 border-gray-border-dark bg-gray-light cursor-pointer;

  &:hover {
    @apply border-blue-dark;
  }

  &.checked {
    @apply border-blue-dark bg-blue-light;
  }
}
