<template>
  <!-- Generator: Adobe Illustrator 21.1.0, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
  <svg
    version="1.1"
    id="Ebene_1"
    xmlns:x="&ns_extend;"
    xmlns:i="&ns_ai;"
    xmlns:graph="&ns_graphs;"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 756 361"
    style="enable-background: new 0 0 756 361"
    xml:space="preserve"
  >
    <switch>
      <foreignObject requiredExtensions="&ns_ai;" x="0" y="0" width="1" height="1">
        <i:pgfRef xlink:href="#adobe_illustrator_pgf"> </i:pgfRef>
      </foreignObject>
      <g i:extraneous="self">
        <g>
          <path class="st0" d="M90.55908,289.32227v48.14941h-3.14355v-48.14941H90.55908z" />
          <path
            class="st0"
            d="M117.26367,304.01562c1.85303,0,3.56836,0.31934,5.14551,0.95898
				c1.57715,0.64062,2.94531,1.57227,4.10352,2.79688s2.0625,2.72949,2.71387,4.5166
				c0.65039,1.78711,0.97607,3.83887,0.97607,6.15527c0,0.48535-0.07178,0.81641-0.21533,0.99316
				c-0.14307,0.17676-0.36914,0.26465-0.67725,0.26465h-23.76123v0.62891c0,2.4707,0.28662,4.64453,0.86035,6.51855
				c0.57373,1.87598,1.39014,3.44727,2.44922,4.71582c1.05859,1.26953,2.33789,2.22266,3.83838,2.8623
				c1.5,0.64062,3.17676,0.95996,5.03027,0.95996c1.6543,0,3.08838-0.18164,4.30176-0.5459
				c1.21289-0.36426,2.23389-0.77148,3.06104-1.22461c0.82715-0.45215,1.4834-0.86035,1.96924-1.22461
				c0.48486-0.36328,0.83789-0.5459,1.05859-0.5459c0.28662,0,0.50732,0.11133,0.66211,0.33105l0.86035,1.05957
				c-0.52979,0.66113-1.22998,1.2793-2.10156,1.85254c-0.87158,0.57422-1.84229,1.06445-2.91211,1.47266
				c-1.07031,0.4082-2.21729,0.7334-3.44141,0.97656c-1.22461,0.24219-2.45459,0.36426-3.68994,0.36426
				c-2.25,0-4.30176-0.39258-6.15479-1.1748c-1.85352-0.7832-3.44385-1.9248-4.77051-3.42578
				c-1.32764-1.5-2.35352-3.33594-3.07764-5.50977c-0.7251-2.17285-1.08691-4.66016-1.08691-7.46191
				c0-2.36035,0.33594-4.53906,1.00928-6.53613c0.67285-1.99609,1.64307-3.7168,2.91211-5.16211
				c1.26855-1.44531,2.82373-2.57617,4.66602-3.39258C112.83447,304.42383,114.9248,304.01562,117.26367,304.01562z
				 M117.31201,306.36523c-1.69629,0-3.21631,0.26465-4.55957,0.79395c-1.34424,0.5293-2.50586,1.29004-3.48633,2.2832
				s-1.77295,2.18457-2.37891,3.57422s-1.00781,2.94531-1.20605,4.66602h21.67529c0-1.76465-0.24219-3.34766-0.72656-4.74902
				c-0.48535-1.40039-1.16797-2.58594-2.04883-3.55762c-0.88086-0.9707-1.93848-1.71484-3.17236-2.2334
				C120.17529,306.62402,118.80957,306.36523,117.31201,306.36523z"
          />
          <path
            class="st0"
            d="M150.85156,303.98242c1.43359,0,2.76318,0.17676,3.9873,0.5293
				c1.22461,0.35352,2.32178,0.84961,3.29248,1.48926h8.43848v1.0918c0,0.55176-0.30908,0.84961-0.92627,0.89355l-4.99707,0.36426
				c0.61768,0.83887,1.09229,1.78125,1.42285,2.8291c0.33105,1.04785,0.49658,2.16797,0.49658,3.35938
				c0,1.58789-0.28711,3.02734-0.86035,4.31836c-0.57422,1.29004-1.37354,2.39355-2.39941,3.30859
				c-1.02588,0.91602-2.25586,1.62793-3.68945,2.13477c-1.43457,0.50781-3.02295,0.76172-4.76514,0.76172
				c-1.89795,0-3.61865-0.28711-5.1626-0.86133c-0.92676,0.53027-1.65479,1.15918-2.18408,1.88672
				c-0.5293,0.72852-0.79443,1.43457-0.79443,2.11816c0,0.94824,0.33105,1.67676,0.99316,2.18359
				c0.66162,0.50781,1.53857,0.87695,2.63086,1.1084c1.0918,0.23242,2.33789,0.38086,3.73926,0.44727
				c1.40088,0.06641,2.82373,0.1377,4.26904,0.21484c1.44434,0.07812,2.86719,0.21484,4.26855,0.41406
				c1.40088,0.19824,2.64746,0.5459,3.73926,1.04199c1.09229,0.49707,1.96924,1.19141,2.63086,2.08496
				c0.66211,0.89355,0.99316,2.0791,0.99316,3.55762c0,1.36719-0.34229,2.68066-1.02588,3.9375
				c-0.68408,1.25781-1.67139,2.37207-2.96191,3.34277s-2.85156,1.74805-4.68262,2.33301
				c-1.83154,0.58398-3.88281,0.87695-6.15527,0.87695c-2.31641,0-4.34619-0.2373-6.08887-0.71191s-3.20459-1.11426-4.38477-1.91895
				c-1.18018-0.80566-2.06787-1.7373-2.66357-2.7959c-0.5957-1.05957-0.89355-2.18457-0.89355-3.37598
				c0-1.74316,0.57324-3.24316,1.7207-4.5c1.14697-1.25781,2.72461-2.22852,4.73242-2.91309
				c-1.10352-0.37402-1.98047-0.91504-2.63086-1.62109c-0.65137-0.70605-0.97656-1.6875-0.97656-2.94531
				c0-0.46289,0.08789-0.94824,0.26514-1.45605c0.17627-0.50684,0.44092-1.00879,0.79395-1.50586
				c0.35254-0.49609,0.77783-0.96484,1.27393-1.40625c0.49658-0.44141,1.07568-0.83789,1.7373-1.19141
				c-1.56641-0.9043-2.78516-2.10645-3.65625-3.60645c-0.87207-1.5-1.30713-3.25391-1.30713-5.26172
				c0-1.58887,0.28125-3.02832,0.84375-4.31934c0.5625-1.29004,1.35645-2.39844,2.38232-3.3252s2.26709-1.64355,3.72314-2.15137
				C147.47607,304.23633,149.08594,303.98242,150.85156,303.98242z M163.0625,339.68848c0-1.03711-0.27051-1.86914-0.81104-2.49805
				s-1.26318-1.11914-2.16748-1.47266c-0.90479-0.35254-1.94727-0.60059-3.12695-0.74414
				c-1.18066-0.14355-2.41602-0.24902-3.70654-0.31445c-1.29053-0.06641-2.58691-0.13281-3.88818-0.19922
				c-1.30225-0.06543-2.51514-0.19824-3.64014-0.39648c-0.83887,0.33105-1.61621,0.71094-2.33301,1.1416
				c-0.71729,0.42969-1.32959,0.91504-1.83691,1.45605s-0.90479,1.13574-1.19092,1.78711
				c-0.28711,0.65039-0.43018,1.36133-0.43018,2.13379c0,0.9707,0.24805,1.87012,0.74414,2.69727
				c0.49658,0.82715,1.22461,1.54395,2.18408,2.15137c0.95996,0.60645,2.13477,1.08594,3.52441,1.43945
				c1.39014,0.35254,2.98926,0.5293,4.79834,0.5293c1.67627,0,3.2373-0.1875,4.68262-0.5625
				c1.44482-0.375,2.70215-0.90527,3.77246-1.58887c1.06982-0.68359,1.9082-1.5,2.51514-2.44824
				C162.75879,341.85059,163.0625,340.81445,163.0625,339.68848z M150.85156,322.91113c1.41113,0,2.66895-0.2041,3.77246-0.6123
				c1.10254-0.4082,2.0293-0.97656,2.77979-1.7041c0.74951-0.72852,1.31787-1.59961,1.7041-2.61426
				c0.38574-1.01465,0.5791-2.13965,0.5791-3.37598c0-1.23535-0.19873-2.36621-0.5957-3.3916
				c-0.39697-1.02637-0.97607-1.90332-1.7373-2.63086c-0.76123-0.72852-1.68799-1.29102-2.77979-1.6875
				c-1.09229-0.39746-2.33301-0.5957-3.72266-0.5957c-1.39014,0-2.63672,0.19824-3.73975,0.5957
				c-1.10352,0.39648-2.03516,0.95898-2.79639,1.6875c-0.76074,0.72754-1.33984,1.60449-1.7373,2.63086
				c-0.39697,1.02539-0.59521,2.15625-0.59521,3.3916c0,1.23633,0.19824,2.36133,0.59521,3.37598
				c0.39746,1.01465,0.97656,1.88574,1.7373,2.61426c0.76123,0.72754,1.69287,1.2959,2.79639,1.7041
				C148.21484,322.70703,149.46143,322.91113,150.85156,322.91113z"
          />
          <path
            class="st0"
            d="M196.71631,337.47168c-0.66211,0-1.07031-0.30859-1.22461-0.92676l-0.46338-4.20215
				c-0.90479,0.88184-1.80322,1.67676-2.69678,2.38184c-0.89355,0.70703-1.82568,1.30273-2.79639,1.78711
				c-0.97119,0.48535-2.01855,0.85547-3.14355,1.1084c-1.12549,0.25391-2.36084,0.38086-3.70654,0.38086
				c-1.125,0-2.21729-0.16504-3.27588-0.49609c-1.05908-0.33105-1.99707-0.83789-2.81299-1.52246
				c-0.81641-0.68359-1.47266-1.56055-1.96875-2.63086c-0.49658-1.06934-0.74463-2.35449-0.74463-3.85547
				c0-1.38965,0.39697-2.67969,1.19092-3.87109c0.79443-1.19141,2.03516-2.22852,3.72314-3.11133
				c1.6875-0.88184,3.86035-1.58789,6.51904-2.11719c2.6582-0.53027,5.85156-0.83887,9.58008-0.92676v-3.44238
				c0-3.04395-0.65674-5.3877-1.96875-7.03125c-1.31299-1.64355-3.25977-2.46582-5.84082-2.46582
				c-1.58838,0-2.93994,0.2207-4.05371,0.66211c-1.11426,0.44141-2.05762,0.92676-2.82959,1.45605
				c-0.77246,0.5293-1.40137,1.01465-1.88623,1.45605c-0.48535,0.44141-0.89355,0.66211-1.22412,0.66211
				c-0.44189,0-0.77246-0.19922-0.99316-0.5957l-0.5625-0.95996c1.7207-1.7207,3.52979-3.02246,5.42725-3.90527
				c1.89697-0.88184,4.04785-1.32324,6.45312-1.32324c1.76465,0,3.30908,0.28125,4.63281,0.84375s2.4209,1.36816,3.29248,2.41602
				c0.87109,1.04785,1.52734,2.31641,1.96924,3.80566c0.44092,1.48828,0.66162,3.14941,0.66162,4.97949v21.44434H196.71631z
				 M183.47949,335.71777c1.2793,0,2.45361-0.1377,3.52393-0.41406c1.06982-0.27539,2.06299-0.66113,2.97852-1.1582
				c0.91504-0.49609,1.77051-1.08594,2.56445-1.77051c0.79443-0.68359,1.57715-1.42285,2.34961-2.2168v-8.57129
				c-3.13281,0.08887-5.82959,0.32617-8.09082,0.71191c-2.26172,0.38672-4.12549,0.91016-5.59277,1.57227
				c-1.46729,0.66113-2.54785,1.4502-3.24268,2.36523c-0.69531,0.91602-1.04248,1.95898-1.04248,3.12793
				c0,1.10352,0.18164,2.05762,0.5459,2.8623c0.36377,0.80566,0.84375,1.46777,1.43945,1.98535
				c0.5957,0.51855,1.29053,0.89941,2.08496,1.1416C181.7915,335.59668,182.61914,335.71777,183.47949,335.71777z"
          />
          <path class="st0" d="M214.61865,289.32227v48.14941h-3.14355v-48.14941H214.61865z" />
          <path
            class="st0"
            d="M259.92041,338.00098c-2.229,0-3.96045-0.61719-5.1958-1.85254
				c-1.23584-1.23535-1.85303-3.14453-1.85303-5.72559v-22.50293h-4.73242c-0.24268,0-0.44141-0.06641-0.59521-0.19824
				c-0.15479-0.13281-0.23193-0.31934-0.23193-0.5625v-1.22461l5.62598-0.39746l0.78564-11.78027
				c0.02197-0.19922,0.10352-0.375,0.24512-0.5293s0.33301-0.23242,0.57324-0.23242h1.47314v12.5752h10.35791v2.34961h-10.35791
				v22.33789c0,0.9043,0.11621,1.68262,0.34766,2.33301s0.55127,1.18555,0.95947,1.60449
				c0.4082,0.41992,0.88818,0.72852,1.43945,0.92676s1.14697,0.29785,1.78711,0.29785c0.79443,0,1.47803-0.11621,2.05176-0.34766
				c0.57324-0.23145,1.06982-0.48438,1.48926-0.76074c0.41895-0.27539,0.76123-0.5293,1.02588-0.76172
				c0.26465-0.23145,0.47412-0.34668,0.62842-0.34668c0.17676,0,0.35303,0.11035,0.52979,0.33008l0.86035,1.39062
				c-0.83887,0.92676-1.90283,1.6709-3.19336,2.2334S261.31006,338.00098,259.92041,338.00098z"
          />
          <path
            class="st0"
            d="M289.60352,304.01562c1.85303,0,3.56836,0.31934,5.14551,0.95898
				c1.57715,0.64062,2.94531,1.57227,4.10352,2.79688s2.0625,2.72949,2.71387,4.5166
				c0.65039,1.78711,0.97607,3.83887,0.97607,6.15527c0,0.48535-0.07178,0.81641-0.21533,0.99316
				c-0.14307,0.17676-0.36914,0.26465-0.67725,0.26465h-23.76123v0.62891c0,2.4707,0.28662,4.64453,0.86035,6.51855
				c0.57373,1.87598,1.39014,3.44727,2.44922,4.71582c1.05859,1.26953,2.33789,2.22266,3.83838,2.8623
				c1.5,0.64062,3.17676,0.95996,5.03027,0.95996c1.6543,0,3.08838-0.18164,4.30176-0.5459
				c1.21289-0.36426,2.23389-0.77148,3.06104-1.22461c0.82715-0.45215,1.4834-0.86035,1.96924-1.22461
				c0.48486-0.36328,0.83789-0.5459,1.05859-0.5459c0.28662,0,0.50732,0.11133,0.66211,0.33105l0.86035,1.05957
				c-0.52979,0.66113-1.22998,1.2793-2.10156,1.85254c-0.87158,0.57422-1.84229,1.06445-2.91211,1.47266
				c-1.07031,0.4082-2.21729,0.7334-3.44141,0.97656c-1.22461,0.24219-2.45459,0.36426-3.68994,0.36426
				c-2.25,0-4.30176-0.39258-6.15479-1.1748c-1.85352-0.7832-3.44385-1.9248-4.77051-3.42578
				c-1.32764-1.5-2.35352-3.33594-3.07764-5.50977c-0.7251-2.17285-1.08691-4.66016-1.08691-7.46191
				c0-2.36035,0.33594-4.53906,1.00928-6.53613c0.67285-1.99609,1.64307-3.7168,2.91211-5.16211
				c1.26855-1.44531,2.82373-2.57617,4.66602-3.39258C285.17432,304.42383,287.26465,304.01562,289.60352,304.01562z
				 M289.65186,306.36523c-1.69629,0-3.21631,0.26465-4.55957,0.79395c-1.34424,0.5293-2.50586,1.29004-3.48633,2.2832
				s-1.77295,2.18457-2.37891,3.57422s-1.00781,2.94531-1.20605,4.66602h21.67529c0-1.76465-0.24219-3.34766-0.72656-4.74902
				c-0.48535-1.40039-1.16797-2.58594-2.04883-3.55762c-0.88086-0.9707-1.93848-1.71484-3.17236-2.2334
				C292.51514,306.62402,291.14941,306.36523,289.65186,306.36523z"
          />
          <path
            class="st0"
            d="M334.67383,308.94629c-0.11035,0.11035-0.21484,0.2041-0.31445,0.28125
				c-0.09912,0.07715-0.2373,0.11523-0.41357,0.11523c-0.2207,0-0.52441-0.14844-0.91016-0.44629
				c-0.38623-0.29785-0.89893-0.62305-1.53857-0.97656c-0.64014-0.35254-1.43945-0.67773-2.39941-0.97559
				c-0.95947-0.29785-2.12354-0.44727-3.49121-0.44727c-1.89746,0-3.57959,0.33691-5.04639,1.00977
				c-1.46729,0.67285-2.70264,1.6377-3.70605,2.89551c-1.00439,1.25684-1.77051,2.7793-2.30029,4.56641
				c-0.5293,1.78711-0.79395,3.79492-0.79395,6.02246c0,2.31738,0.27002,4.36914,0.81055,6.15527
				c0.54053,1.78711,1.30713,3.29297,2.30029,4.51758c0.99268,1.22461,2.19482,2.15625,3.60693,2.7959
				c1.41162,0.64062,2.97803,0.95996,4.69873,0.95996c1.58887,0,2.91748-0.1875,3.98779-0.5625
				c1.06982-0.375,1.95264-0.7832,2.64746-1.22461c0.69482-0.44043,1.24609-0.84863,1.6543-1.22461
				c0.4082-0.375,0.7334-0.5625,0.97656-0.5625c0.28613,0,0.50732,0.11133,0.66162,0.33105l0.86035,1.05957
				c-0.50781,0.66113-1.14746,1.2793-1.91943,1.85254c-0.77246,0.57422-1.64355,1.07031-2.61426,1.48926
				s-2.02393,0.74512-3.16016,0.97656s-2.32227,0.34766-3.55713,0.34766c-2.09619,0-4.01025-0.38086-5.7417-1.14258
				c-1.73193-0.76074-3.22119-1.86328-4.46729-3.30859c-1.24658-1.44531-2.21729-3.21582-2.91211-5.31152
				s-1.04248-4.47852-1.04248-7.14844c0-2.51465,0.33105-4.80859,0.99268-6.88281c0.66211-2.07324,1.62695-3.86035,2.89551-5.36133
				c1.26855-1.5,2.82373-2.66309,4.66602-3.49121c1.8418-0.82715,3.95459-1.24023,6.3374-1.24023
				c2.13965,0,4.04199,0.3418,5.70801,1.02539c1.66553,0.68457,3.11621,1.61035,4.35156,2.78027L334.67383,308.94629z"
          />
          <path
            class="st0"
            d="M345.75879,337.47168v-48.14941h3.14404v20.84863c1.52197-1.875,3.25928-3.37012,5.21191-4.48438
				c1.95215-1.11426,4.1416-1.6709,6.56885-1.6709c1.80859,0,3.40234,0.28711,4.78174,0.86035
				c1.37842,0.57324,2.52002,1.40137,3.4248,2.48145c0.9043,1.08105,1.58838,2.38281,2.05176,3.90527
				s0.69482,3.24316,0.69482,5.16211v21.04688h-3.14355V316.4248c0-3.08789-0.70654-5.50977-2.11816-7.26367
				c-1.41211-1.75391-3.57373-2.63086-6.48584-2.63086c-2.16211,0-4.16943,0.55762-6.02295,1.6709
				c-1.85303,1.11426-3.50732,2.64258-4.96338,4.58398v24.68652H345.75879z"
          />
          <path
            class="st0"
            d="M420.41309,308.64844c-0.1543,0.30859-0.39648,0.46289-0.72754,0.46289
				c-0.24316,0-0.55762-0.1377-0.94336-0.41309c-0.38672-0.27637-0.89355-0.58496-1.52246-0.92676s-1.40137-0.65039-2.31641-0.92676
				c-0.91602-0.27539-2.03516-0.41406-3.3584-0.41406c-1.19141,0-2.27832,0.1709-3.25977,0.5127
				c-0.98242,0.34082-1.82031,0.79785-2.51465,1.36914c-0.69531,0.57227-1.23633,1.2373-1.62207,1.99609
				c-0.38574,0.75977-0.5791,1.55762-0.5791,2.39355c0,1.03418,0.26465,1.89258,0.79395,2.57422
				c0.53027,0.68262,1.22461,1.26465,2.08496,1.74902c0.86035,0.4834,1.83691,0.90234,2.92871,1.25391
				c1.0918,0.35254,2.21191,0.70508,3.35938,1.05762c1.14648,0.35352,2.2666,0.74512,3.3584,1.1748
				c1.0918,0.43066,2.06836,0.96582,2.92871,1.60547s1.55566,1.42285,2.08496,2.34961c0.5293,0.92578,0.79395,2.05176,0.79395,3.375
				c0,1.43457-0.25977,2.76855-0.77734,4.00391c-0.51855,1.23633-1.26953,2.30566-2.25,3.20996
				c-0.98242,0.90527-2.19043,1.62207-3.62402,2.15137c-1.43457,0.5293-3.06641,0.79395-4.89746,0.79395
				c-2.31641,0-4.31348-0.36914-5.99023-1.1084s-3.17676-1.7041-4.5-2.89551l0.76074-1.125
				c0.11035-0.17676,0.23145-0.30859,0.36426-0.39746c0.13281-0.08789,0.31934-0.13184,0.5625-0.13184
				c0.28613,0,0.63965,0.17676,1.05859,0.5293s0.96484,0.7334,1.63867,1.1416c0.67285,0.4082,1.51074,0.78906,2.51465,1.1416
				c1.00391,0.35352,2.24512,0.5293,3.72266,0.5293c1.39062,0,2.61426-0.19238,3.67383-0.5791
				c1.05859-0.38574,1.94043-0.91016,2.64746-1.57227c0.70508-0.66113,1.24023-1.43945,1.60449-2.33301
				c0.36426-0.89258,0.5459-1.83594,0.5459-2.8291c0-1.10254-0.26465-2.01855-0.79395-2.74609
				c-0.5293-0.72852-1.22461-1.3457-2.08496-1.85352c-0.86035-0.50684-1.83691-0.9375-2.92871-1.29102
				c-1.0918-0.35254-2.2168-0.70508-3.375-1.05859c-1.1582-0.35254-2.2832-0.73926-3.37598-1.1582
				c-1.0918-0.41895-2.06738-0.94824-2.92871-1.58887c-0.86035-0.63965-1.55469-1.41699-2.08398-2.33301
				c-0.53027-0.91504-0.79492-2.05664-0.79492-3.4248c0-1.16895,0.25391-2.29395,0.76172-3.375
				c0.50684-1.08105,1.22949-2.0293,2.16699-2.84668c0.9375-0.81543,2.0791-1.4668,3.42578-1.95215
				c1.34473-0.48535,2.85645-0.72754,4.5332-0.72754c2.00684,0,3.78906,0.29102,5.34473,0.87207
				c1.55469,0.58203,2.9834,1.4707,4.28516,2.66895L420.41309,308.64844z"
          />
          <path
            class="st0"
            d="M445.92578,304.01562c2.36133,0,4.47266,0.40234,6.33789,1.20801
				c1.86328,0.80469,3.43555,1.94727,4.71484,3.4248c1.2793,1.47852,2.25586,3.25977,2.92871,5.34375
				c0.67285,2.08496,1.00977,4.41797,1.00977,6.99902c0,2.58203-0.33691,4.90918-1.00977,6.9834
				c-0.67285,2.07324-1.64941,3.84961-2.92871,5.32715c-1.2793,1.47852-2.85156,2.61426-4.71484,3.40918
				c-1.86523,0.79395-3.97656,1.19141-6.33789,1.19141c-2.36035,0-4.47266-0.39746-6.33691-1.19141
				c-1.86426-0.79492-3.44141-1.93066-4.73145-3.40918c-1.29102-1.47754-2.27344-3.25391-2.94531-5.32715
				c-0.67383-2.07422-1.00977-4.40137-1.00977-6.9834c0-2.58105,0.33594-4.91406,1.00977-6.99902
				c0.67188-2.08398,1.6543-3.86523,2.94531-5.34375c1.29004-1.47754,2.86719-2.62012,4.73145-3.4248
				C441.45312,304.41797,443.56543,304.01562,445.92578,304.01562z M445.92578,335.41992c1.96387,0,3.67871-0.33594,5.14648-1.00977
				c1.4668-0.67188,2.69141-1.6377,3.67285-2.89551c0.98145-1.25684,1.71484-2.77344,2.20117-4.5498
				c0.48438-1.77539,0.72754-3.7666,0.72754-5.97363c0-2.18359-0.24316-4.16895-0.72754-5.95605
				c-0.48633-1.78711-1.21973-3.31445-2.20117-4.58301s-2.20605-2.24512-3.67285-2.92871
				c-1.46777-0.68359-3.18262-1.02637-5.14648-1.02637c-1.96289,0-3.67871,0.34277-5.14551,1.02637
				s-2.69141,1.66016-3.67285,2.92871c-0.98242,1.26855-1.7207,2.7959-2.21777,4.58301
				c-0.49609,1.78711-0.74414,3.77246-0.74414,5.95605c0,2.20703,0.24805,4.19824,0.74414,5.97363
				c0.49707,1.77637,1.23535,3.29297,2.21777,4.5498c0.98145,1.25781,2.20605,2.22363,3.67285,2.89551
				C442.24707,335.08398,443.96289,335.41992,445.92578,335.41992z"
          />
          <path class="st0" d="M475.90723,289.32227v48.14941h-3.14453v-48.14941H475.90723z" />
          <path
            class="st0"
            d="M492.51855,304.54492v21.04688c0,3.08887,0.70801,5.50977,2.125,7.26367s3.5752,2.63086,6.47461,2.63086
				c2.14648,0,4.15039-0.5459,6.00977-1.63867c1.85938-1.0918,3.53027-2.6084,5.01367-4.5498v-24.75293h3.14355v32.92676h-1.73242
				c-0.61035,0-0.92578-0.28613-0.94727-0.86035l-0.2998-4.89746c-1.52441,1.89746-3.28613,3.41992-5.28516,4.56641
				c-2,1.14746-4.22559,1.7207-6.67773,1.7207c-1.83301,0-3.43555-0.28711-4.80469-0.86035
				c-1.37012-0.57324-2.50781-1.40039-3.41309-2.48145c-0.90625-1.08105-1.59082-2.38281-2.05469-3.90527
				s-0.69629-3.24316-0.69629-5.16211v-21.04688H492.51855z"
          />
          <path
            class="st0"
            d="M537.62207,338.00098c-2.22852,0-3.95996-0.61719-5.19531-1.85254
				c-1.23633-1.23535-1.85352-3.14453-1.85352-5.72559v-22.50293h-4.73242c-0.24316,0-0.44141-0.06641-0.5957-0.19824
				c-0.1543-0.13281-0.23145-0.31934-0.23145-0.5625v-1.22461l5.62598-0.39746l0.78613-11.78027
				c0.02148-0.19922,0.10254-0.375,0.24512-0.5293c0.1416-0.1543,0.33301-0.23242,0.57324-0.23242h1.47266v12.5752h10.3584v2.34961
				h-10.3584v22.33789c0,0.9043,0.11621,1.68262,0.34766,2.33301s0.55078,1.18555,0.95996,1.60449
				c0.40723,0.41992,0.8877,0.72852,1.43945,0.92676c0.55078,0.19824,1.14648,0.29785,1.78711,0.29785
				c0.79395,0,1.47754-0.11621,2.05176-0.34766c0.57324-0.23145,1.06934-0.48438,1.48828-0.76074
				c0.41895-0.27539,0.76172-0.5293,1.02637-0.76172c0.26465-0.23145,0.47363-0.34668,0.62891-0.34668
				c0.17578,0,0.35254,0.11035,0.5293,0.33008l0.86035,1.39062c-0.83887,0.92676-1.90332,1.6709-3.19336,2.2334
				C540.35156,337.71973,539.01172,338.00098,537.62207,338.00098z"
          />
          <path
            class="st0"
            d="M560.35547,293.19434c0,0.39648-0.08301,0.7666-0.24805,1.1084
				c-0.16602,0.3418-0.38086,0.64551-0.64551,0.91016s-0.57422,0.47461-0.92676,0.62891
				c-0.35352,0.1543-0.72754,0.23145-1.125,0.23145s-0.77246-0.07715-1.125-0.23145
				c-0.35352-0.1543-0.66211-0.36426-0.92676-0.62891s-0.47461-0.56836-0.62891-0.91016s-0.23145-0.71191-0.23145-1.1084
				c0-0.39746,0.07715-0.77832,0.23145-1.1416c0.1543-0.36426,0.36426-0.67871,0.62891-0.94336s0.57324-0.47461,0.92676-0.62891
				c0.35254-0.1543,0.72754-0.23145,1.125-0.23145s0.77148,0.07715,1.125,0.23145c0.35254,0.1543,0.66211,0.36426,0.92676,0.62891
				s0.47949,0.5791,0.64551,0.94336C560.27246,292.41602,560.35547,292.79688,560.35547,293.19434z M558.96582,304.54492v32.92676
				h-3.14453v-32.92676H558.96582z"
          />
          <path
            class="st0"
            d="M585.80078,304.01562c2.36133,0,4.47266,0.40234,6.33789,1.20801
				c1.86328,0.80469,3.43555,1.94727,4.71484,3.4248c1.2793,1.47852,2.25586,3.25977,2.92871,5.34375
				c0.67285,2.08496,1.00977,4.41797,1.00977,6.99902c0,2.58203-0.33691,4.90918-1.00977,6.9834
				c-0.67285,2.07324-1.64941,3.84961-2.92871,5.32715c-1.2793,1.47852-2.85156,2.61426-4.71484,3.40918
				c-1.86523,0.79395-3.97656,1.19141-6.33789,1.19141c-2.36035,0-4.47266-0.39746-6.33691-1.19141
				c-1.86426-0.79492-3.44141-1.93066-4.73145-3.40918c-1.29102-1.47754-2.27344-3.25391-2.94531-5.32715
				c-0.67383-2.07422-1.00977-4.40137-1.00977-6.9834c0-2.58105,0.33594-4.91406,1.00977-6.99902
				c0.67188-2.08398,1.6543-3.86523,2.94531-5.34375c1.29004-1.47754,2.86719-2.62012,4.73145-3.4248
				C581.32812,304.41797,583.44043,304.01562,585.80078,304.01562z M585.80078,335.41992c1.96387,0,3.67871-0.33594,5.14648-1.00977
				c1.4668-0.67188,2.69141-1.6377,3.67285-2.89551c0.98145-1.25684,1.71484-2.77344,2.20117-4.5498
				c0.48438-1.77539,0.72754-3.7666,0.72754-5.97363c0-2.18359-0.24316-4.16895-0.72754-5.95605
				c-0.48633-1.78711-1.21973-3.31445-2.20117-4.58301s-2.20605-2.24512-3.67285-2.92871
				c-1.46777-0.68359-3.18262-1.02637-5.14648-1.02637c-1.96289,0-3.67871,0.34277-5.14551,1.02637
				s-2.69141,1.66016-3.67285,2.92871c-0.98242,1.26855-1.7207,2.7959-2.21777,4.58301
				c-0.49609,1.78711-0.74414,3.77246-0.74414,5.95605c0,2.20703,0.24805,4.19824,0.74414,5.97363
				c0.49707,1.77637,1.23535,3.29297,2.21777,4.5498c0.98145,1.25781,2.20605,2.22363,3.67285,2.89551
				C582.12207,335.08398,583.83789,335.41992,585.80078,335.41992z"
          />
          <path
            class="st0"
            d="M610.38867,337.47168v-32.92676h1.7207c0.57324,0,0.9043,0.27637,0.99219,0.82715l0.29785,4.96387
				c1.5-1.89746,3.24805-3.4248,5.24512-4.58301c1.99609-1.1582,4.21973-1.7373,6.66797-1.7373
				c1.80859,0,3.40332,0.28711,4.78223,0.86035c1.37793,0.57324,2.51953,1.40137,3.4248,2.48145
				c0.9043,1.08105,1.58887,2.38281,2.05176,3.90527s0.69531,3.24316,0.69531,5.16211v21.04688h-3.14453V316.4248
				c0-3.08789-0.70605-5.50977-2.11719-7.26367c-1.41211-1.75391-3.57422-2.63086-6.48633-2.63086
				c-2.16211,0-4.16992,0.55762-6.02246,1.6709c-1.85352,1.11426-3.50781,2.64258-4.96387,4.58398v24.68652H610.38867z"
          />
          <path
            class="st0"
            d="M666.97461,308.64844c-0.1543,0.30859-0.39648,0.46289-0.72754,0.46289
				c-0.24316,0-0.55762-0.1377-0.94336-0.41309c-0.38672-0.27637-0.89355-0.58496-1.52246-0.92676s-1.40137-0.65039-2.31641-0.92676
				c-0.91602-0.27539-2.03516-0.41406-3.3584-0.41406c-1.19141,0-2.27832,0.1709-3.25977,0.5127
				c-0.98242,0.34082-1.82031,0.79785-2.51465,1.36914c-0.69531,0.57227-1.23633,1.2373-1.62207,1.99609
				c-0.38574,0.75977-0.5791,1.55762-0.5791,2.39355c0,1.03418,0.26465,1.89258,0.79395,2.57422
				c0.53027,0.68262,1.22461,1.26465,2.08496,1.74902c0.86035,0.4834,1.83691,0.90234,2.92871,1.25391
				c1.0918,0.35254,2.21191,0.70508,3.35938,1.05762c1.14648,0.35352,2.2666,0.74512,3.3584,1.1748
				c1.0918,0.43066,2.06836,0.96582,2.92871,1.60547s1.55566,1.42285,2.08496,2.34961c0.5293,0.92578,0.79395,2.05176,0.79395,3.375
				c0,1.43457-0.25977,2.76855-0.77734,4.00391c-0.51855,1.23633-1.26953,2.30566-2.25,3.20996
				c-0.98242,0.90527-2.19043,1.62207-3.62402,2.15137c-1.43457,0.5293-3.06641,0.79395-4.89746,0.79395
				c-2.31641,0-4.31348-0.36914-5.99023-1.1084s-3.17676-1.7041-4.5-2.89551l0.76074-1.125
				c0.11035-0.17676,0.23145-0.30859,0.36426-0.39746c0.13281-0.08789,0.31934-0.13184,0.5625-0.13184
				c0.28613,0,0.63965,0.17676,1.05859,0.5293s0.96484,0.7334,1.63867,1.1416c0.67285,0.4082,1.51074,0.78906,2.51465,1.1416
				c1.00391,0.35352,2.24512,0.5293,3.72266,0.5293c1.39062,0,2.61426-0.19238,3.67383-0.5791
				c1.05859-0.38574,1.94043-0.91016,2.64746-1.57227c0.70508-0.66113,1.24023-1.43945,1.60449-2.33301
				c0.36426-0.89258,0.5459-1.83594,0.5459-2.8291c0-1.10254-0.26465-2.01855-0.79395-2.74609
				c-0.5293-0.72852-1.22461-1.3457-2.08496-1.85352c-0.86035-0.50684-1.83691-0.9375-2.92871-1.29102
				c-1.0918-0.35254-2.2168-0.70508-3.375-1.05859c-1.1582-0.35254-2.2832-0.73926-3.37598-1.1582
				c-1.0918-0.41895-2.06738-0.94824-2.92871-1.58887c-0.86035-0.63965-1.55469-1.41699-2.08398-2.33301
				c-0.53027-0.91504-0.79492-2.05664-0.79492-3.4248c0-1.16895,0.25391-2.29395,0.76172-3.375
				c0.50684-1.08105,1.22949-2.0293,2.16699-2.84668c0.9375-0.81543,2.0791-1.4668,3.42578-1.95215
				c1.34473-0.48535,2.85645-0.72754,4.5332-0.72754c2.00684,0,3.78906,0.29102,5.34473,0.87207
				c1.55469,0.58203,2.9834,1.4707,4.28516,2.66895L666.97461,308.64844z"
          />
        </g>
        <path
          class="st0"
          d="M208.69629,89.812h38.18361c-1.2227,10.99271-4.89211,28.7124-7.33301,37.26421h0.6113
			c10.08301-19.2412,26.27101-41.2364,48.87309-41.2364c7.0249,0,13.13522,1.2227,17.103,3.0528l-13.43408,38.1836
			c-3.05762-1.52641-7.3335-2.74471-12.832-2.74471c-20.77249,0-42.1563,19.8526-56.5098,102.0215l-2.13771,12.21581H181.8188
			L208.69629,89.812z"
        />
        <path
          class="st0"
          d="M315.70999,89.812h38.18359c-0.61191,8.2485-3.97269,24.4365-6.41409,33.9028h0.6069
			c10.08249-17.103,24.74469-37.875,52.54248-37.875c22.90921,0,31.15332,15.5816,31.15332,36.0455h0.61139
			c12.52341-19.5494,27.79681-36.0455,53.14841-36.0455c20.46869,0,32.38177,12.524,32.38177,38.1836
			c0,6.105-0.6123,12.8272-1.8349,20.1607l-17.10837,94.3847h-39.09772l16.80472-92.24701
			c0.91409-4.27589,1.22269-8.55669,1.22269-11.9126c0-8.55669-3.05771-13.74709-10.9971-13.74709
			c-16.4971,0-36.6572,23.522-51.01169,105.6909l-2.1377,12.21581h-39.40631l16.7998-92.24701
			c0.91989-4.27589,1.22269-7.93999,1.22269-11.3008c0-8.5518-3.05759-14.35889-11.30078-14.35889
			c-15.8848,0-36.04541,22.91019-50.70752,105.6909l-2.1377,12.21581H288.832L315.70999,89.812z"
        />
        <path
          class="st1"
          d="M599.38177,89.812h39.40143L611.90039,238.5688h-39.70508L599.38177,89.812z M601.82318,45.5186
			c0-12.5196,10.07721-23.2139,23.82428-23.2139c14.0556,0,24.13385,10.6943,24.13385,23.2139
			c0,12.832-10.07825,23.5214-24.13385,23.5214C611.90039,69.04,601.82318,58.3506,601.82318,45.5186z"
        />
        <path
          class="st1"
          d="M658.33197,209.2451c0-5.8022,0.92004-12.21581,2.13873-19.5493l12.83197-70.2563h-23.52136l5.19525-29.6275
			h24.12793l6.7168-38.1831l42.15619-11.3013l-9.16791,49.4844h37.56744l-5.19153,29.6275h-37.87012l-12.2207,67.1987
			c-0.914,4.89211-1.52631,8.8598-1.52631,12.2207c0,6.4136,2.14349,9.46629,7.33392,9.46629
			c8.23828,0,15.26862-5.1904,21.9903-9.7744l12.51849,27.1855c-10.0722,7.3335-25.96289,16.4966-49.1709,16.4966
			C669.33008,242.23289,658.33197,232.1553,658.33197,209.2451z"
        />
        <path
          class="st0"
          d="M95.56789,89.812L97.7109,77.5967c4.2754-22.607,15.5767-29.6323,34.81739-29.6323
			c14.05521,0,23.83011,4.5839,29.02051,7.6367l16.4966-28.7124c-9.47121-5.8018-22.9101-11.3008-47.0386-11.3008
			c-40.3213,0-64.14639,18.3262-72.69769,62.6152c0,0-2.1431,11.60889-2.4458,11.60889H4.748l-5.1958,29.6275h51.1202
			L28.7217,238.5688h39.5459l21.5029-119.1293h44.8955l-21.6865,119.1293h39.71479L179.57179,89.812H95.56789z"
        />
      </g>
    </switch>
  </svg>
</template>

<script setup lang="ts"></script>

<style scoped>
.st0 {
  fill: #173c5d;
}

.st1 {
  fill: #939cb2;
}
</style>
