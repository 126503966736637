@import "../frontend/_variables";

.autocomplete-suggestions {
  position: absolute;
  z-index: 2;
  border: 1px solid #999;
  border-radius: 10px;
  background: #fff;
  overflow: auto;
  max-height: 300px;
  width: 100%;

  :not(.autocomplete-no-suggestion) {
    cursor: pointer;

    &:hover, &:focus, &.focus {
      background-color: $hoverBlue;
    }
  }
}

.autocomplete-suggestion,
.autocomplete-no-suggestion,
.autocomplete-too-many-results {
  padding: 10px;
  overflow: hidden;
}

.autocomplete-suggestion,
.autocomplete-no-suggestion,
.autocomplete-not-connected-to-zefix {
  padding: 10px;
  overflow: hidden;
}

.autocomplete-selected {
  background: #f0f0f0;
}

.autocomplete-suggestions strong {
  font-weight: bold;
}

.autocomplete-group {
  padding: 2px 5px;
}

.autocomplete-group strong {
  display: block;
  border-bottom: 1px solid #000;
}

.autocomplete-input-group {
  position: relative;
}

.autocomplete-spinner {
  position: absolute;
  bottom: 10px;
  right: 10px;
  opacity: 0;

  &.show {
    opacity: 1;
  }

  i,
  svg {
    font-size: 20px;
  }
}
