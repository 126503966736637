.alert {
  margin-bottom: 0;
}

.container-flashes {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 31;

  .flashes {
    .alert {
      padding: 0.75rem 1.25rem;
    }

    .close-icon {
      color: inherit;
      font-size: 1.5em;
    }

    .flash-text-wrapper {
    }
  }
}
.flashes-margin-bottom {
  margin-bottom: 1em;
}
