
// Tailwind overrides
// TODO: Remove once bootstrap is gone!
.collapse {
    visibility: visible;
}

// Re-apply former reboot styles
.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
    margin-bottom: 0.5rem;
    margin-top: 0;
    line-height: 1.2;
    color: inherit;
}

img, svg {
    display: initial;
}

p {
    margin-bottom: 1rem;
    margin-top: 0;
}

hr {
    margin: 1rem 0;
    color: inherit;
    border: 0;
    border-top: 1px solid;
    opacity: 0.25;
}

ul {
    padding-left: 2rem;
    list-style-type: disc;
}

ul ul {
    list-style-type: circle;
}

ul ul ul {
    list-style-type: square;
}

dl, ol, ul {
    margin-bottom: 1rem;
    margin-top: 0;
}

ol,
ul {
    padding-left: 2rem;
}
dl,
ol,
ul {
    margin-bottom: 1rem;
    margin-top: 0;
}
ol ol,
ol ul,
ul ol,
ul ul {
    margin-bottom: 0;
}
dt {
    font-weight: 700;
}
dd {
    margin-bottom: 0.5rem;
    margin-left: 0;
}

ol {
    list-style-type: decimal;

    &[type="a"] {
        list-style-type: lower-alpha;
    }
}

