.background {
  background-color: $firmItLightBlue;
  background-size: cover;
  position: fixed;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  z-index: -1;
}

body {
  min-height: 100vh;

  .nav-open {
    overflow: hidden;
  }
}

.popover {
  max-width: 400px;
}

.cc-revoke {
  transform: rotate(-90deg) !important;
  right: -25px !important;
  bottom: 30px !important;
}

th {
  &:active,
  &:focus {
    outline: none !important;
  }
}

p {
  a {
    color: rgba(102, 175, 233, 1);
  }
}

.title-wrapper {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 5px 0 10px 0;

  .action-bar-buttons > a {
    margin-right: 5px;
    margin-bottom: 5px;
  }
}

h1,
h2,
h5,
h6 {
  font-weight: 700;
}

h3,
h4 {
  font-weight: 600;
}

h1 {
  font-size: 44px;
}

h2 {
  font-size: 34px;

  &.sm {
    font-size: 28px;
  }
}

h3 {
  font-size: 24px;

  &.sm {
    font-size: 22px;
    font-weight: 700;
  }
}

h4 {
  font-size: 20px;
}

h5 {
  font-size: 1rem;
}

h6 {
  font-size: 0.9rem;
}

h3 {
  i {
    color: rgba(0, 0, 0, 0.5);
    transition: all 200ms ease;

    &:hover {
      color: black;
    }
  }
}

.header {
  padding: 0px 20px 15px;
  margin: 0px -30px 30px;
  border-bottom: 1px solid $firmItDarkGrey;

  div.page-title {
    display: inline-flex;
    align-items: center;
    height: 44px;

    h4 {
      margin: 0;
    }
  }

  .nav-icon {
    width: 40px;
    height: 35px;
    margin-top: 15px;
    position: relative;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: 0.5s ease-in-out;
    -moz-transition: 0.5s ease-in-out;
    -o-transition: 0.5s ease-in-out;
    transition: 0.5s ease-in-out;
    cursor: pointer;
    float: left;

    span {
      display: block;
      position: absolute;
      height: 3px;
      width: 100%;
      background: $colorDarkBlue;
      border-radius: 3px;
      opacity: 1;
      left: 0;
      -webkit-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
      -webkit-transition: 0.25s ease-in-out;
      -moz-transition: 0.25s ease-in-out;
      -o-transition: 0.25s ease-in-out;
      transition: 0.25s ease-in-out;

      &:nth-child(1) {
        top: 0;
      }

      &:nth-child(2),
      &:nth-child(3) {
        top: 11px;
      }

      &:nth-child(4) {
        top: 22px;
      }
    }

    &.open span {
      &:nth-child(1) {
        top: 18px;
        width: 0;
        left: 50%;
      }

      &:nth-child(2) {
        -webkit-transform: rotate(45deg);
        -moz-transform: rotate(45deg);
        -o-transform: rotate(45deg);
        transform: rotate(45deg);
      }

      &:nth-child(3) {
        -webkit-transform: rotate(-45deg);
        -moz-transform: rotate(-45deg);
        -o-transform: rotate(-45deg);
        transform: rotate(-45deg);
      }

      &:nth-child(4) {
        top: 18px;
        width: 0;
        left: 50%;
      }
    }
  }

  .small-nav {
    float: right;
    font-size: 16px;

    div.dropdown {
      display: inline-block;
      margin-left: 10px;
    }

    ul {
      margin-bottom: 0;
      float: right;
    }

    .lang-disabled {
      opacity: 0.3;
    }

    .lang-nav ul {
      margin-bottom: 0;

      li {
        margin: 2px 5px;
      }
    }

    li {
      list-style: none;
      float: left;
      margin: 10px 0 10px 20px;

      a {
        color: $colorDarkBlue;
      }
    }
  }
}

@media (max-width: 500px) {
  header {
    .logo {
      width: 100px;
    }
  }
}

.nav .nav-button {
  font-size: 14px;
  padding: 10px 15px;
  border: 2px solid black;
  transition: all 200ms ease;
  border-radius: 4px;
  color: black;

  &:hover {
    background: transparent;
    border-color: rgba(0, 0, 0, 0.4);
    color: rgba(0, 0, 0, 0.4);
  }
}

.container-sm {
  width: 500px;
}

.register {
  margin: 80px auto;
}

.btn {
  font-size: 14px;
  font-weight: 700;
  padding: 10px 15px;
  transition: 200ms all ease;
  border-radius: 10px;

  &.btn-sm {
    font-size: 12px;
    padding: 5px 10px;
  }

  &.btn-big {
    font-size: 16px;
    padding: 15px 20px;
  }

  &.disabled {
    cursor: default;
  }
}

.btn-primary,
.btn-primary:focus,
.btn-primary:active,
.btn-primary.disabled,
.btn-primary:disabled {
  color: white !important;
  background-color: $colorDarkBlue;
  border: 1.5px solid $colorDarkBlue;

  &:hover:not(.disabled) {
    background-color: $firmItDarkBlue;
  }
}

button.btn-secondary,
button.btn-secondary:focus,
button.btn-secondary:active,
button.btn-secondary.disabled,
button.btn-secondary:disabled {
  background-color: $colorSecondaryGray;
}

.btn-light {
  color: $colorDarkBlue;
  background-color: white;
  border: 1.5px solid $colorDarkBlue;

  &:hover:not(.disabled) {
    background-color: $colorDarkBlue;
    color: white;
    border: 1.5px solid white;
  }

  &.back {
    border: 1.5px solid $colorDarkBlue;
    font-weight: 600;
  }
}

.btn-success {
  background-color: $colorLightGreen;
  border-color: $colorGreen;
  color: $colorGreen;

  .trash-icon {
    color: $colorGreen;
  }

  &:hover {
    color: white;

    .trash-icon {
      color: white;
    }
  }
}

.btn-danger {
  background-color: $colorLightRed;
  border-color: $colorRed;
  color: $colorRed;

  .trash-icon {
    color: $colorRed;
  }

  &:hover {
    color: white;

    .trash-icon {
      color: white;
    }
  }
}

a {
  color: $colorDarkBlue;

  &:hover {
    color: $colorBlue;
    text-decoration: none;
    cursor: pointer;
  }

  &.active {
    background: $colorDarkBlue;
    color: white;
  }
}

a.card-layout {
  display: inline-flex;
  min-width: 32px;
  min-height: 32px;
  padding: 0 10px;
  border-radius: 5px;
  align-items: center;
  justify-content: center;
  background-color: $colorLightBlue;
  color: $colorBlue;
  border: 1px solid $colorBlue;

  &:hover {
    background-color: $colorBlue;
    color: white;
    border-color: $colorBlue;
  }

  &.delete {
    background-color: $colorLightRed;
    color: $colorRed;
    border-color: $colorRed;

    &:hover {
      background-color: $colorRed;
      color: white;
      border-color: $colorRed;
    }
  }

  &:not(:last-child) {
    margin-right: 5px;
  }
}

a.popover_link {
  > i {
    color: rgba(0, 0, 0, 0.5);
  }

  color: rgba(0, 0, 0, 0.5);
  transition: all 200ms ease;

  &:hover {
    color: black;
  }
}

a.basic-link {
  color: $colorBlue;

  &:hover {
    cursor: pointer;
  }
}

.form-select {
  color: $colorDarkBlue;
  border: 1.5px solid $colorDarkBlue;
  border-radius: $default_border_radius_very_small;
  height: 44px;

}

.form-control {
  @extend .form-select;
  padding: 0.5rem 0.75rem;
}

.form-control2 {
  background: none;
  box-shadow: none;
  border: none;
  border-bottom: 2px solid black;
  padding: 6px;
  height: 39px;
  font-size: 16px;
  font-weight: 300;

  &:focus {
    box-shadow: none;
  }
}

#table_founder_search_input {
  background: none;
  box-shadow: none;
  border: 1px solid $firmItLightGrey;
}

#table_founder {
  .arrow-icon-header {
    width: 25px;
  }

  .arrow-icon {
    color: lightgray;
    width: 25px;
    height: 25px;
  }
}

textarea.form-control {
  height: 200px;
}

// We need this because this class got removed in bootstrap 5
.form-group {
  margin-bottom: 1rem;
}
// This is extremly hacky, but we want popover links to be close to the label #sosad
.form-group {
  > label,
  > legend {
    display: inline;
  }

  a {
    //color: black;
    //padding-top: 0;
    //display: inline;

    &:hover {
      text-decoration: none;
    }
  }
}

.form-children-no-margin * {
  .form-group {
    margin: 0;
  }

  .form-check {
    margin: 0;
  }
}

.form-check {
  padding-left: 0;
}

.form-check-input {
  position: absolute;
  margin-left: 10px;
  z-index: -1;
  opacity: 0;
}

.form-check-label {
  position: relative;
  vertical-align: top;
}

.form-check-label::before {
  position: relative;
  float: left;
  margin: 0 5px;
  display: block;
  pointer-events: none;
  content: "";
  background-color: #fff;
  border: 1px solid $firmItGray;
  width: $checkbox-width;
  height: $checkbox-height;
  //left: calc(0px - (#{$checkbox-width} * 1));
  top: -0.15rem;
  border-radius: 8px;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
  box-shadow 0.15s ease-in-out;
}

.form-check-label.must-be-checked::before {
  border-color: $colorRed;
}

.form-check-label::after {
  position: absolute;
  display: block;
  margin: 0 5px;
  cursor: pointer;
  content: "";
  background: 50% / 50% 50% no-repeat;
  width: $checkbox-width;
  height: $checkbox-height;
  //left: calc(0px - (#{$checkbox-width} * 1));
  top: -0.15rem;
}

.form-check-input:checked ~ .form-check-label::before {
  color: #fff;
  border-color: white;
  background-color: $colorDarkBlue;
}

.form-check-input.disabled ~ .form-check-label::before {
  opacity: 0.5;
}

.form-check-input.disabled ~ .form-check-label {
  pointer-events: none;
}

.form-check-input:checked ~ .form-check-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e");
}

.form-check {
  margin-bottom: 0.5rem;
}

.lightblue-background {
  background-color: $firmItLightBlue;
}

.darkblue-background {
  background-color: $colorDarkBlue !important;
}

.ultra-lightgrey-background {
  background-color: $firmItUltraLightGrey;
}

.very-lightgrey-background {
  background-color: $firmItVeryLightGrey;
}

.lightgrey-background {
  background-color: $firmItLightGrey;
}

.modal .content-box {
  min-height: auto;
}

.modal .modal-content {
  border-radius: $default_border_radius !important;
}

.content-box {
  padding: 20px 50px;

  @media (max-width: $breakpoint-sm) {
    padding: 20px 20px;
  }

  .content-header {
    display: flex;
    justify-content: space-between;
    @media screen and (max-width: 576px) {
      flex-flow: row wrap;
    }
  }

  .action-bar {
    text-align: right;
    margin-bottom: 1em;
  }

  .toolbar {
    margin: (-30px) -50px;
    height: 60px;
    padding: 10px 10px;

    > li {
      list-style: none;
      display: inline-block;
      position: relative;
      float: right;
      margin-left: 10px;

      > a {
        display: inline-block;
        height: 40px;
        line-height: 40px;
        border: 1px solid black;
        padding: 0 15px;
        color: black;

        &:hover {
          background: rgba(0, 0, 0, 0.1);
          color: black;
        }
      }

      ul {
        visibility: hidden;
        transform: scaleY(0);
        transition: all 200ms ease;
        transform-origin: top;
        padding: 0;
        border: 1px solid black;
        background: white;
        position: absolute;
        z-index: 3;

        li {
          list-style: none;

          a {
            height: 40px;
            line-height: 40px;
            padding: 0 15px;
            white-space: nowrap;
            color: black;
          }
        }
      }

      &:hover {
        ul {
          visibility: visible;
          transform: scaleY(1);
        }
      }
    }
  }
}

.modal {
  .content-box {
    padding: 0;
  }

  .toolbar {
    display: none;
  }
}

footer {
  display: flex;
  align-items: center;
  min-height: $footer-height;
  padding: 0 50px;
  margin: 2rem 0 0 $navigation-width;

  position: relative;
  bottom: 0;

  border-top: 1px solid $firmItDarkGrey;
  font-size: 16px;

  p,
  ul {
    margin: 0;
    padding: 0;
  }

  ul {
    li {
      list-style: none;
      float: left;
      margin-right: 10px;
      //margin: 10px 0 10px 20px;

      &.separator {
        padding-right: 20px;
        border-right: 1px solid $firmItDarkBlue;
      }
    }
  }
}

.sidebar {
  padding: 0;
  margin-top: 60px;
  margin-right: -30px;

  li {
    background: rgba(255, 255, 255, 0.3);
    padding: 15px 20px;
    list-style: none;

    a {
      color: black;
      font-size: 18px;
      text-transform: uppercase;
      font-weight: 300;
      transition: all 200ms ease;
    }

    &:hover {
      background: rgba(255, 255, 255, 0.5);
    }

    &.active {
      background: rgba(255, 255, 255, 0.7);
    }
  }
}

.step-list-container {
  width: fit-content;
}

.step-list,
.documents-list {
  padding: 0;
  margin: 0;

  li {
    padding: 5px 20px;
    @media (max-width: 992px) {
      padding: 5px 0;
    }

    list-style: none;
    transition: all 200ms ease;

    a {
      white-space: nowrap;
    }

    a,
    span {
      color: $firmItDarkBlue;
      font-size: 16px;
      text-transform: uppercase;
      font-weight: 300;
      transition: all 200ms ease;
      min-width: 23px;
      display: inline-block;
      text-align: center;
      vertical-align: -1px;
    }

    i,
    svg {
      margin-right: 5px;
    }

    &:hover a,
    &:hover span {
      color: rgba(102, 175, 233, 0.6);
    }

    &.active a,
    &.active span {
      //color: rgba(102, 175, 233, 1);
      font-weight: 600;
    }
  }
}

.documents-list {
  margin-top: 30px;
  margin-bottom: 30px;

  li {
    padding: 5px 0;
    color: black;
    font-size: 16px;
    text-transform: uppercase;
    font-weight: 300;
    transition: all 200ms ease;

    a {
      font-size: 16px;
      white-space: initial;
      text-align: left;
    }
  }
}

.personen {
  .person {
    position: relative;
    padding: 20px;
    background: rgba(0, 0, 0, 0.05);
    margin-bottom: 30px;

    [data-remove] {
      position: absolute;
      top: 10px;
      right: 10px;
      color: black;
    }
  }

  [data-add] {
    margin: 20px 0;
    display: block;
    color: black;
  }
}

.aktien {
  .aktie {
    position: relative;
    padding: 20px;
    background: rgba(0, 0, 0, 0.05);
    margin-bottom: 30px;

    [data-remove] {
      position: absolute;
      top: 10px;
      right: 10px;
      color: black;
    }
  }

  [data-add] {
    margin: 20px 0;
    display: block;
    color: black;
  }
}

.popover-header {
  margin: 0;
}

.action-card {
  height: 200px;
  padding: 20px;
  text-align: center;
  color: black;
  transition: all 200ms ease;
  border: 2px solid black;

  &:hover {
    background: transparent;
    border-color: rgba(0, 0, 0, 0.4);
    color: rgba(0, 0, 0, 0.4);
  }

  .icon {
    font-size: 40px;
    margin-bottom: 20px;
  }

  .price {
    font-size: 28px;
  }
}

p.price {
  margin: 0 10px 0 0;
  font-size: 28px;
}

.button-with-more-info {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.row-as-table {
  .row {
    border-bottom: 1px solid black;

    &:first-child {
      border-top: 1px solid black;
    }

    [class*="col-"] {
      border-right: 1px solid black;
    }

    [class*="col-"]:first-child {
      border-left: 1px solid black;
    }
  }
}

.box {
  border: 1px solid black;
  padding: 1rem;
}

.logo-login {
  margin: 20px auto;
  max-width: 230px;
}

.nav-toggle {
  position: absolute;
  top: 15px;
  left: 0;
  margin: 10px 8px;
  padding: 0;
  z-index: 3;
  border: none;
  background: none;
}

nav {
  position: fixed;
  height: 100vh;
  min-width: 40px;
  z-index: 2;
  box-shadow: 0 3px 6px $colorLightGrayBoxShadow;
  background-color: white;

  @media screen and (max-width: 576px) {
    min-height: auto;
  }

  #nav {
    height: 100%;

    .nav-content {
      width: $navigation-width;
      display: flex;
      flex-direction: column;
      padding: $navigation-padding;
      overflow-y: auto;
      height: 100%;

      a.btn-primary {
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 16px;
      }

      div.logo {
        width: 180px;
        margin: auto;
      }

      div.divider {
        border-top: 1px solid $firmItLightGrey;
        margin: 30px 0;
      }

      ul {
        padding: 0;
        margin: 0 0 50px;
        flex-grow: 1;

        li {
          height: 60px;
          font-size: 16px;

          a {
            display: block;
            height: 100%;
            padding: 18px 0 0 11px;
            color: $colorDarkBlue;
            border-radius: $default_border_radius_small;

            &:hover {
              color: $colorBlue;
            }

            &.active {
              background: $firmItSemiLightBlue;

              &::before {
                position: absolute;
                top: 0;
                left: calc(0px - $navigation-padding - 15px);
                width: 20px;
                height: 60px;
                border-radius: $default_border_radius_small;
                background: $colorDarkBlue;
                content: "";
              }
            }

            p {
              margin: auto 10px;
              display: inline;
            }

            h6 {
              margin: -4px 0 0 28px;
              font-size: 12px;
              color: $firmItDarkGrey;
            }
          }
        }
      }

      div.navigation-footer {
        margin: 0 -20px -20px;

        div.profile {
          width: 100%;
          padding: 0 20px;
          margin-bottom: 10px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          cursor: pointer;

          &.active {
            > a {
              color: white;
              background-color: $colorDarkBlue;
            }
          }

          > a {
            flex-grow: 1;
            padding: 4px 10px;
            color: $colorDarkBlue;
            border-radius: 8px;

            p {
              font-size: 16px;
              margin: 0;

              &:first-child {
                font-size: 12px;
              }
            }
          }

          span {
            display: flex;
            justify-content: flex-end;
            flex-grow: 0.1;
            font-size: 25px;
          }
        }

        div.company-identifier {
          height: $footer-height;
          width: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          padding: 0 20px;
          background-color: #f7fafc;

          p {
            font-size: 16px;
            margin: 0;
          }
        }
      }
    }
  }
}

.main-content {
  margin-left: $navigation-width;
}

@media (max-width: $breakpoint-xl) {
  .main-content {
    margin-left: 40px;
  }

  footer {
    margin: 2rem 0 0 40px;
  }

  .navigation #nav .nav-content div.logo {
    position: relative;
    top: 5px;
    width: 70px;
  }
}

@media (min-width: $breakpoint-xl) {
  #nav {
    display: block;
  }
}

.content-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;

  @media (max-width: $breakpoint-lg) {
    flex-wrap: wrap;
  }

  align-items: flex-start;
  justify-content: center;

  & > div:first-of-type {
    min-width: 50%;
    margin-bottom: 10px;
  }

  & > div:last-child {
    @media (max-width: $breakpoint-lg) {
      max-width: 100%;
    }

    margin: 0 20px;
  }
}

.help-label {
  margin-left: 0.33rem;
  color: $colorDarkBlue;

  &:hover {
    color: $colorBlue;
  }
}

.payment-method {
  .form-check {
    border: 1px solid black;
    border-radius: 4px;
    margin-bottom: 5px;
    height: 50px;
    padding-top: 14px;
    padding-left: 30px;

    input {
      margin-top: 0.25rem;
    }
  }

  img {
    width: 50px;
    height: auto;
    float: right;
    margin-right: 5px;
  }
}

div.tab-nav h4 {
  display: inline-block;
  margin: 0 40px 30px 0;
  padding-bottom: 7px;
  color: $firmItGray;
  cursor: pointer;

  &.active,
  &:hover {
    color: $colorDarkBlue;
  }

  &.active {
    border-bottom: 2px solid $colorBlue;
  }

  a {
    color: inherit;
  }
}

div.content-table {
  background-color: white;
  padding: 25px 0;
  border-radius: $default_border_radius;
  box-shadow: 0 3px 6px $colorLightGrayBoxShadow;

  div.title-bar {
    display: flex;
    justify-content: space-between;
    padding: 0 30px 20px 30px;
    align-items: center;

    h2 {
      margin: 0;
      padding: 0;
    }
  }

  div.no-processes {
    padding: 0 $content-padding-y;
  }
}

div.filter {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  height: 45px;

  input {
    width: 60%;
    display: inline;
  }

  select {
    width: 70px;
  }

  label {
    margin: 2px 0 0 10px;
    max-width: 80px;
  }
}

div.table-responsive {
  border-radius: $default_border_radius_very_small;
}

table.table {
  color: $colorDarkBlue;

  th,
  td {
    &:first-child {
      padding-left: 30px;
    }

    &:last-child {
      padding-right: 30px;
    }
  }

  thead tr {
    height: 40px;
    background-color: $firmItSemiLightBlue;
    font-size: 13px;

    th {
      vertical-align: middle;
      // border: 0;
      font-weight: 300;
      text-transform: uppercase;

      span {
        margin-left: 0.5rem;
        cursor: pointer;

        span {
          margin-left: 0;
        }
      }
    }
  }

  tbody tr {
    height: 50px;
    background-color: white;

    &:hover {
      background-color: $hoverBlue !important;
    }

    td {
      vertical-align: middle;

      &:last-child {
        text-align: end;

        div {
          min-width: 120px;
        }
      }

      a {
        @extend .card-layout;
      }
    }
  }
}

table.table-striped {
  tbody tr:nth-of-type(odd) {
    --bs-table-striped-bg: #{$firmItLightBlue};
  }
}

div ul.pagination {
  justify-content: end;
  margin: 25px 30px 0 0;

  li {
    margin: 0 3px;

    &.page-item.active .page-link {
      background-color: $colorDarkBlue;
      border-color: $colorDarkBlue;
      color: white;
    }

    .page-link {
      border-radius: 10px !important;
      color: inherit;
    }
  }
}

.table-offers {
  td,
  th {
    padding: 0.2rem;
  }

  h3 {
    padding: 0;
    margin-bottom: 10px;
    margin-top: 30px;
  }

  strong {
    font-weight: 400;
  }

  i,
  svg {
    margin-right: 0.66rem;
  }
}

.init-summary-table {
  background-color: $firmItLightGrey;

  div.summary-row-coloring:nth-child(odd) {
    background-color: $firmItLightBlue;
  }
}

.yes-no-widget-header {
  margin-top: 1em;
}

.yes-no-widget {
  .form-check {
    padding: 0;
    input {
      text-align: center;
      padding: 0;
      margin: 0;
    }
  }
  .choice {
    align-items: center;
    justify-content: center;
    display: flex;
  }
}

.hidden {
  display: none;
}

.rounded-corners {
  border-radius: 5px;
}

.scrollbox {
  max-height: 500px;
  overflow-y: auto;
}

.big-scrollbox {
  max-height: 1000px;
  overflow-y: auto;
}

.horizontal-padding {
  padding-left: $content-padding-y;
  padding-right: $content-padding-y;
}

div.content {
  background-color: white;
  padding: 25px $content-padding-y;

  &.no-horizontal-padding {
    padding-left: 0;
    padding-right: 0;
  }

  border-radius: $default_border_radius;
  box-shadow: 0 3px 6px $colorLightGrayBoxShadow;

  &.extra-padding {
    padding: 40px 45px;
  }
}

div.services {
  label {
    display: block;
  }

  input {
    display: inline-block;
  }

  form {
    width: 100%;
  }

  button.submit,
  a.submit {
    margin-top: 20px;
  }

  select {
    display: inline-block;
    width: 500px;
  }

  .submit-select {
    margin: -4px 0 0 25px;
    float: initial;
  }
}

div.options {
  width: 100%;
  min-height: 20px;
  overflow: hidden;
  padding: 15px;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  border-radius: $default_border_radius;
  border: 1px solid $firmItDarkGrey;

  .option {
    //flex-grow: 0.5;
    //flex-shrink: 1;
    //flex-basis: 0;
    position: relative;
    min-width: 20%;
    margin: 15px 1%;
    padding: 0 10px;
    height: 100px;
    border-radius: $default_border_radius;
    border: 2px solid $firmItDarkGrey;
    background-color: $firmItSemiLightBlue;
    cursor: pointer;

    @media (max-width: $breakpoint-md) {
      & {
        flex-grow: 1;
      }
    }

    &.disabled {
      cursor: not-allowed;
    }

    &:hover,
    &.selected {
      border: 2px solid $colorDarkBlue;
    }

    &.selected {
      div.icon {
        visibility: visible;
      }
    }

    &.option-payment {
      flex: 1 1 auto;
      &.option-large {
        flex: 3 1 auto;
      }
    }

    .payment-logo {
      margin-left: 10px;
      height: 40px;
    }

    div.form-check {
      height: 100%;
      padding: 0;

      input {
        display: none;
      }

      label {
        height: 100%;
        padding: 0;
        font-size: 14px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        cursor: pointer;

        &:before,
        &:after {
          display: none;
        }
      }
    }

    p.option-text {
      height: 100%;
      width: 100%;
      position: relative;
      top: -27px;
      //padding: 20px;
      //margin: 0;
      font-size: 14px;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
    }

    div.icon {
      position: absolute;
      top: 5px;
      right: 12px;
      float: right;
      margin-left: -5px;
      font-size: 18px;
      visibility: hidden;
    }
  }

  .option-signature {
    width: 100%;
    height: 50px;
    margin: 10px 0;

    div.form-check {
      label {
        justify-content: left;
        text-align: left;
      }
    }

    div.icon {
      top: 10px;
      font-size: 18px;

      &.disabled {
        opacity: 0.5;
      }
    }
  }
}

div.signature_options {
  width: 100%;
  min-height: 100px;
  overflow: hidden;
  padding: 15px;
  display: flex;
  flex-direction: column;
  border-radius: $default_border_radius;
  border: 1px solid $firmItDarkGrey;

  .option {
    display: flex;
    flex-direction: column;
    border-radius: $default_border_radius;
    border: 2px solid $firmItDarkGrey;
    background-color: $firmItSemiLightBlue;

    &:not(:last-child) {
      margin-bottom: 10px;
    }

    div.icon {
      font-size: 18px;
    }

    &:hover,
    &.selected {
      border: 2px solid $colorDarkBlue;
    }

    div.phone-icon {
      border: 1px solid;
      border-radius: 20px;
      padding: 3px 12px;
      background-color: $firmItGray;
      color: white;

      &.mandatory {
        opacity: 0.5;
      }

      &.confirmed {
        background-color: $colorDarkBlue;
      }

      .loading {
        margin: 0 -4px;
      }
    }

    div.form-check {
      padding: 0;
      margin: 0;
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;

      label {
        margin: 15px 10px;
        height: 100%;
        width: 100%;
        cursor: pointer;

        &:before,
        &:after {
          top: 0;
        }
      }
    }

    .esig-fields {
      display: flex;
      flex-direction: column;
      margin: 10px 35px 20px 35px;

      .esig-field {
        display: flex;
        align-items: center;

        label {
          width: 50px;
          margin: 0 1rem 0 0;
        }
      }
    }

    .btn-danger {
      display: block;
      width: 165px;
    }
  }
}

div.mutation {
  div.summary_data {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    overflow: hidden;

    hr {
      height: 6rem;
      margin: auto -10px;
      /* border-reset*/
      border: none;
      border-left: 1px solid $firmItDarkGrey;
    }

    hr:last-child {
      /* there is one */
      display: none;
    }
  }

  padding-bottom: 20px;

  div.persons {
    width: 100%;
    overflow: hidden;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;

    div.person {
      margin: 5px;
      padding: 15px 20px;
      border-radius: $default_border_radius;
      border: 2px solid $firmItDarkGrey;
      background-color: $firmItSemiLightBlue;

      p {
        margin: 0;
      }
    }
  }
}

.bordered {
  border-radius: $default_border_radius;
  border: 1px solid $firmItDarkGrey;
  padding: 15px;
}

.form-footer {
  margin: 20px (-$content-padding-y) -30px (-$content-padding-y);
  padding: 25px 20px 30px;
  border-top: 1px solid $firmItDarkGrey;

  .extra-margin {
    margin: 80px -30px -30px;
  }

  button {
    float: right;
  }
}

.modal-form-footer {
  margin: 20px -25px 0;
  padding: 30px 25px 0 25px;
  border-top: 1px solid $firmItDarkGrey;

  .extra-margin {
    margin: 80px -30px -30px;
  }

  button {
    float: right;
  }
}

.modal-form-footer-margin {
  margin: 20px -15px -20px;
}

.switch-signature-wrapper {
  background-color: $firmItSemiLightBlue;
  color: $colorDarkBlue;
  border-radius: 10px;

  .btn {
    border: 0;
  }

  .active {
    box-shadow: none;
    background-color: $colorDarkBlue;
    color: $firmItSemiLightBlue;
    border-radius: 10px !important;
  }

  .form-check,
  label {
    margin: 0;
    padding: 0;
    cursor: pointer;
    min-height: 0;
    //transition: background-color .35s ease-in-out, border-color .35s ease-in-out, box-shadow .35s ease-in-out, color .1s ease-in-out;

    ::after {
      content: none;
    }

    ::before {
      content: none;
    }
  }

  .new-label {
    position: absolute;
    top: 0;
    right: 0;
    margin-top: -14px;
    margin-right: -12px;
    z-index: 1;
    background-color: $colorWarning;
    border: 1px solid #8d670c;
    color: #8d670c;

    -moz-border-radius: 50px;
    -webkit-border-radius: 50px;
    border-radius: 50px;
  }
}

.mt-4-5 {
  margin-top: 2rem;
}

.open-esig {
  position: relative;
  border-radius: $default_border_radius;
  border: 1px solid $firmItBorderGray;

  &:not(&:last-child) {
    margin-bottom: 15px;
  }

  .upper,
  {
    display: flex;
    justify-content: space-between;
    padding: 10px 20px;
    align-items: center;

    h6 {
      margin-bottom: 1rem;
    }

    p:last-child,
    h4:last-child,
    h6:last-child {
      margin: 0;
    }

    h6.gray {
      display: inline;
      margin-right: 10px;
      color: $firmItGray;
    }
  }

  .lower {
    flex-direction: column;
    background-color: $firmItLightBlue;
    border-radius: 0 0 20px 20px;
    border-top: 2px solid $firmItLightGrey;
    padding: 10px 20px;
  }
}

.todo-item {
  display: flex;
  border-radius: $default_border_radius;
  border: 1px solid $firmItBorderGray;
  padding: 10px 15px;
  justify-content: space-between;
  align-items: center;

  &:not(&:last-child) {
    margin-bottom: 15px;
  }

  h5 {
    margin: 0;
  }

  .todo {
    padding-right: 25px;
  }

  .deadline {
    display: flex;
    align-items: center;
    height: 50px;
    border-left: 1px solid $firmItLightGrey;
    padding-left: 25px;

    h6 {
      color: $firmItGray;
    }
  }
}

.carousel {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;

  .carousel-control {
    display: flex;

    ol.carousel-indicators {
      justify-content: flex-end;
      align-items: center;
      margin: 0;
      position: initial;

      li {
        background-color: $firmItGray;
        height: 15px;
        width: 15px;
        border-radius: 10px;
        border-top: none;
        border-bottom: none;

        &.active {
          background-color: $colorDarkBlue;
        }
      }
    }

    .carousel-control-prev,
    .carousel-control-next {
      position: relative;
      color: black !important;
      width: auto;
      border: none;
    }
  }

  .carousel-inner {
    margin-left: -$carousel-item-margin;
    width: calc(100% + 2 * 10px);
  }

  .carousel-item {
    .item-container {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;

      .item {
        max-height: 250px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: $carousel-item-margin;
      }
    }
  }
}

h5.collapsible {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
  cursor: pointer;

  svg {
    display: inline-block;
    transition: 0.3s ease-in-out all;
  }

  &.collapsed svg {
    transform: rotate(-180deg);
  }
}

.login-background {
  background-color: $firmItDarkBlue;
  background-blend-mode: multiply;
  background-size: cover;
  background-image: url("../../images/login_background.jpg");
}

.login-container-left,
.login-container-right {
  max-width: 600px;
}

.isBeingDeleted {
  opacity: 0;
  transition: 0.3s ease-in-out all;
}

.text-color-blue {
  color: $colorBlue;
}

.text-danger {
  color: $colorRed !important;
}

.collection-widget {
  display: flex;
  font-size: 16px;
  height: 44px;
  width: 44px;
  padding: 0 10px;
  border-radius: 5px;
  align-items: center;
  justify-content: center;
  background-color: $colorLightBlue;
  color: $colorBlue;
  border: 1px solid $colorBlue;

  &.delete {
    background-color: $colorLightRed;
    color: $colorRed;
    border-color: $colorRed;

    &:hover {
      color: white;
      background-color: $colorRed;
    }
  }
}

.date-fields {
  height: auto;

  select {
    margin-right: 5px;
  }
}

.e-sig-document, .signer-invite {
  display: flex;
  flex-direction: column;
  padding: 10px 20px;
  border: 1.5px solid $colorDarkBlue;
  border-radius: $default_border_radius;

  &.declined {
    border: 1.5px solid $colorRed;
  }

  &:not(:last-child) {
    margin-bottom: 1rem;
  }

  &.disabled {
    border: 1.5px solid $firmItBorderGray;

    .document-name h5, .signer-infos p {
      color: $firmItBorderGray;
    }
  }

  .document-status, .invite-details {
    position: relative;
    min-height: 60px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .document-name, .signer {
      max-width: 50%;

      h5,
      p {
        margin-bottom: 0;
      }
    }

    .signer-infos, .email-infos {
      width: 40%;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }

  a.show {
    display: flex;
    align-items: center;
    height: 32px;
    padding: 0 10px;
    border-radius: 5px;
    background-color: $colorLightBlue;
    color: $colorBlue;
    border: 1px solid $colorBlue;

    &:hover {
      background-color: $colorBlue;
      color: white;
    }

    &.declined {
      background-color: $colorLightRed;
      color: $colorRed;
      border: 1px solid $colorRed;

      &:hover {
        background-color: $colorRed;
        color: white;
      }
    }
  }
}

#modalSigningStatus div.e-sig-document {
  background-color: $firmItSemiLightBlue;
}

div.qr-code {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-bottom: 1rem;
}

img.qr-code {
  height: 150px;
}

.identity-card {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;

  .uploaded {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    .img-container {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 60px;
      width: 100px;
      margin-right: 10px;
      border-radius: 10px;
      border: 2px solid $firmItDarkBlue;

      img {
        position: absolute;
        max-height: 50px;
        max-width: 90px;
      }
    }
  }

  .upload {
    width: 100%;
    padding: 0;
    overflow: hidden;
    margin-top: 1rem;

    label {
      position: relative;
      height: 100%;
      width: 100%;
      border: 2px solid $firmItDarkBlue;
      background-color: white;

      &:hover,
      &.dragged-over {
        background-color: $firmItLightBlue;
      }

      div:not(.hidden) {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
        height: 100%;

        span {
          width: 60%;

          &.icon {
            font-size: 40px;
          }
        }

        img {
          max-height: 80px;
          max-width: 70px;
        }
      }

      input {
        cursor: pointer;
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0;
        width: 100%;
        height: 100%;
        display: block;
      }
    }
  }

  .collection-widget {
    width: 35px;
    height: 35px;
    font-size: 14px;
  }
}

.jur-person-signer {
  background-color: white;
  border: 1px solid $colorDarkBlue;

  &:hover {
    cursor: pointer;
    background-color: $hoverBlue;
  }
}

.product-choice-label {
  border: 1px solid $colorDarkBlue;
  border-radius: $default_border_radius_small;

  &:hover {
    background-color: $hoverBlue;
  }
}

.evidence-iframe {
  width: 100%;
  min-height: 600px;
  border: none;
  border-radius: $default_border_radius;
  overflow: scroll;
}

.zoho-form-iframe {
  width: 100%;
  height: 80vh;
  border: none;
  border-radius: $default_border_radius;
  overflow: scroll;
}

.third-party-logo {
  width: 100px;
}

.founder-init-summary {
  //min-width: 50%;
}

.text-success {
  color: $colorGreen !important;
}

.bg-success {
  background-color: $colorLightGreen !important;
}

.text-danger {
  color: $colorRed !important;
}

.bg-danger {
  background-color: $colorLightRed !important;
}

.price-overview {
  margin: -1.5rem 0 -1rem -1.5rem;
  width: calc(100% + 3.2rem);
  border-radius: 20px;
}

td:has(a) {
  margin-top: -0.5px;
}

.horizontal-divider {
  position: absolute;
  left: 50%;
  height: 80%;
  margin: 0 15px;
  border-left: 1.5px solid $firmItBorderGray;
}

.mirror-y {
  transform: rotateY(180deg);
}

.btn-close {
  @apply text-black;
  --bg: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23000%27%3e%3cpath d=%27M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z%27/%3e%3c/svg%3e");
  box-sizing: content-box;
  width: 1em;
  height: 1em;
  padding: 0.25em 0.25em;
  background: transparent var(--bg) center / 1em auto no-repeat;
  border: 0;
  border-radius: 0px;
  opacity: 0.5;
}

.form-group {
  margin-bottom: 1em;
}
