
.compensate-old-modal {
  margin-left: calc(-1 * var(--bs-modal-padding) - var(--bs-gutter-x) * 0.5);
  margin-right: calc(-1 * var(--bs-modal-padding) - var(--bs-gutter-x) * 0.5);
  margin-top: -35px;
}

:deep(hr) {
  display: block !important;
}
