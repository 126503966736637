
.fade-enter-active,
.fade-leave-active,
.fade-enter-active::backdrop,
.fade-leave-active::backdrop {
  transition: opacity 0.2s ease;
}

.fade-enter-from,
.fade-leave-to,
.fade-enter-from::backdrop,
.fade-leave-to::backdrop {
  opacity: 0;
}
