.appear-enter-active,
.appear-leave-active {
  @apply transition-opacity duration-300 ease-in-out;
}
.appear-enter-from,
.appear-leave-to {
  @apply opacity-0;
}

.list-enter-active,
.list-leave-active {
  @apply transition-[opacity,transform] duration-500 ease-in-out;
}
.list-enter-from,
.list-leave-to {
  @apply opacity-0 translate-x-8;
}

.page-fade-enter-active {
  @apply transition-opacity duration-300;
}
.page-fade-leave-active {
  @apply transition-opacity duration-200;
}
.page-fade-enter-from,
.page-fade-leave-to {
  @apply opacity-0;
}
