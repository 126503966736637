<template>
  <Transition name="appear" mode="out-in">
    <div v-if="model">
      <h2 v-if="!ready" class="flex justify-center items-center grow mb-0 p-6">
        <i class="fa fa-spinner-third fa-spin" />
      </h2>
      <div
        class="flex flex-col align-items-center border-2 border-gray-borderColor rounded p-8"
        :class="{ hide: !ready }"
      >
        <div class="mb-6">
          <Cropper
            ref="cropper"
            :src="imageSrc"
            :auto-zoom="true"
            :default-size="
              ({ imageSize, visibleArea }) => {
                return {
                  width: (visibleArea || imageSize).width,
                  height: (visibleArea || imageSize).height,
                };
              }
            "
            default-boundaries="fill"
            class="cropper"
            @change="debouncedSetCroppedImage"
            @ready="() => (ready = true)"
          />
        </div>
        <div class="flex gap-2">
          <button
            class="btn btn-light"
            type="button"
            @click="cropper.zoom(1.25)"
            :disabled="!ready"
          >
            <i class="far fa-plus"></i>
          </button>
          <button
            class="btn btn-light"
            type="button"
            @click="cropper.zoom(0.75)"
            :disabled="!ready"
          >
            <i class="far fa-minus"></i>
          </button>

          <button
            class="btn btn-light"
            type="button"
            @click="cropper.rotate(90)"
            :disabled="!ready"
          >
            <i class="far fa-undo mirror-y"></i>
          </button>
          <button
            class="btn btn-light"
            type="button"
            @click="cropper.rotate(-90)"
            :disabled="!ready"
          >
            <i class="far fa-undo"></i>
          </button>
        </div>
        <div class="flex gap-2">
          <button class="btn btn-primary mt-2" type="button" @click="handleSave" :disabled="!ready">
            {{ translate('messages', 'action.save') }}
          </button>
          <button class="btn btn-light mt-2" type="button" @click="handleCancel" :disabled="!ready">
            {{ translate('messages', 'action.cancel') }}
          </button>
        </div>
      </div>
    </div>
  </Transition>
</template>

<script setup lang="ts">
import { Cropper } from 'vue-advanced-cropper';
import 'vue-advanced-cropper/dist/style.css';
import 'vue-advanced-cropper/dist/theme.bubble.css';
import { toBlobPromise } from '@/js/frontend/helpers';

interface Props {
  imageSrc: string;
}

defineProps<Props>();
const emit = defineEmits(['save']);
const model = defineModel<boolean>();

const ready = ref(false);
const imageChanged = ref(false);
const userAborted = ref(false);
let croppedImage: File | undefined;
const cropper = ref<typeof Cropper>() as Ref<typeof Cropper>;

const hasSaved = ref(false);

function handleSave() {
  hasSaved.value = true;
  userAborted.value = false;
  model.value = false;
  emit('save', croppedImage);
}

function handleCancel() {
  userAborted.value = true;
  model.value = false;
}

async function setCroppedImage(canvas: HTMLCanvasElement) {
  // only update value on user interaction since this is also called on first init of Cropper
  if (!imageChanged.value && croppedImage !== undefined) {
    imageChanged.value = true;
  }

  croppedImage = await toBlobPromise(canvas, '', 'image/jpeg');
}

const debouncedSetCroppedImage = useDebounceFn(({ canvas }) => setCroppedImage(canvas), 200);

onBeforeUnmount(() => {
  // only save image if user cropped it, not if he clicks on "Abbrechen" (even after cropping)
  // Note: This only works in popupForms (like NatPerson Forms), NOT in standalone vue apps as in UploadSignature.vue
  if (!userAborted.value && imageChanged.value && !hasSaved.value) {
    emit('save', croppedImage);
  }
});
</script>

<style scoped>
div.preview-container {
  border: 2px solid #3b516f;
  border-radius: 7px;
  display: flex;
  align-items: center;
}

.hide {
  width: 0 !important;
  height: 0 !important;
  overflow: hidden !important;
  border: none !important;
}

div.preview-content {
  text-align: center;
  padding: 40px 30px 20px 30px;
}

div.buttons {
  margin-top: 20px;
}

:deep(.cropper) {
  width: 400px;
  height: 400px;
}

:deep(.vue-simple-line) {
  border-color: red !important;
}

:deep(.vue-simple-handler) {
  background-color: red !important;
}
</style>
